<template>
  <div class="row">
    <div class="container">
    <h1>Support Our Tamil Blockchain Initiative </h1>
    <h5>Request for Community Support: Adding Liquidity for USDC/TAMIL Pair</h5>
    <hr>
        <p>Dear Global Tamil Community,</p>
        <p>We are excited to share our P2P project based on the Stellar blockchain, where we use a Stellar token as an incentive for participants in our ecosystem. To enhance our project's functionality and user experience, we are seeking community support to add liquidity for the USDC/TAMIL pair.</p>
        
        <h5>Why Add Liquidity?</h5>
        <p>Adding liquidity helps to stabilize the market for our tokens, ensures smoother transactions, and enhances overall confidence in our project. Your support in this effort will directly contribute to the success and sustainability of our ecosystem.</p>
        
        <h5>How to Add Liquidity</h5>
        <p>We have prepared a detailed guide on how to add liquidity for the USDC/TAMIL pair. You can find this guide <a href="#">here</a>. It walks you through the entire process step-by-step.</p>
        
        <h5>Benefits for Liquidity Providers</h5>
        <p>To show our appreciation, we offer attractive incentives for those who provide liquidity. Details of these rewards can be found in our guide.</p>
        
        <h5>Get in Touch</h5>
        <p>If you have any questions or need further assistance, please don't hesitate to contact us at <a href="mailto:support@tamilcrypto.com">support@tamilcrypto.com</a>.</p>
        
        <p>Thank you for your support!</p>

      </div>
  </div>
</template>


<style>
.container {
  text-align:left
}
</style>
